<template>
  <div id="app">
    <b-container
      v-if="!isAuthResolved"
      class="vh-100 d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Carregando..."
        variant="primary"
      />
    </b-container>

    <component
      :is="layout + '-layout'"
      v-else-if="layout !== ''"
    >
      <router-view />
    </component>

    <div v-else>
      Aguardando layout...
    </div>

    <ErrorModal
      v-model="errorModal"
      :message="errorMessage"
      @ok="onErrorModalOk"
      @back="onErrorModalBack"
    />

    <OfflineModal v-model="offline" />

    <MandatoryUpdateModal
      v-model="mandatoryUpdate"
      @open="openAppStore"
    />
  </div>
</template>

<script>
import axios from '@/axios'
import utils from '@/api/utils.js'
import DefaultLayout from './layouts/DefaultLayout.vue'
import AuthLayout from './layouts/AuthLayout.vue'
import EnrollmentLayout from '@/layouts/EnrollmentLayout.vue'
import IframeLayout from '@/layouts/IframeLayout.vue'
import ErrorModal from '@/components/app/ErrorModal.vue'
import OfflineModal from '@/components/app/OfflineModal.vue'
import MandatoryUpdateModal from '@/components/app/MandatoryUpdateModal.vue'
import * as Sentry from '@sentry/vue'
import { Network } from '@capacitor/network'
// import { Capacitor } from '@capacitor/core'
import { AppUpdate } from '@capawesome/capacitor-app-update'

export default {
  components: {
    // Layouts
    DefaultLayout,
    AuthLayout,
    EnrollmentLayout,
    IframeLayout,

    // Modals
    ErrorModal,
    OfflineModal,
    MandatoryUpdateModal
  },

  data: () => ({
    mandatoryUpdate: false,
    isAuthResolved: false,

    // TODO: Usar loggedIn
    loggedIn: false,
    offline: false,

    // ErrorModal
    errorModal: false,
    errorMessage: null
  }),

  computed: {
    alertFeedBack () {
      return this.$store.state.alertFeedBack
    },

    layout () {
      return this.$store.state.layout
    },

    sizeMobile () {
      return this.$store.state.sizeMobile
    }
  },

  watch: {
    'alertFeedBack.status': function (status) {
      if (status !== true) {
        return
      }

      const h = this.$createElement
      let iconalert
      if (this.alertFeedBack.type === 'success') {
        iconalert = h('b-icon-check-circle-fill', { props: { variant: 'success', fontScale: '1.5' } })
      } else if (this.alertFeedBack.type === 'fail') {
        iconalert = h('b-icon-exclamation-octagon-fill', { props: { variant: 'danger', fontScale: '1.5' } })
      }
      const msgalert = h(
        'div',
        { class: ['row', 'mb-0'] },
        [
          h(
            'div',
            { class: ['col-auto', ' pr-0'] },
            [
              iconalert
            ]),
          h(
            'div',
            { class: ['col', 'mb-0'] },
            [
              h('span', { class: '' }, this.alertFeedBack.msg)
            ])
        ]
      )
      this.$bvToast.toast(msgalert || 'alerta', {
        noCloseButton: true,
        toaster: 'b-toaster-bottom-center',
        solid: true,
        variant: this.alertFeedBack.variant,
        appendToast: true
      })
      this.$store.commit('setAlertFeedBack', false)
    }
  },

  async created () {
    Network.addListener('networkStatusChange', this.onNetworkStatusChange)
    window.addEventListener('resize', this.setSize)
    this.setSize()
    this.setupMobile()
    this.setupAxiosToken()

    const role = JSON.parse(localStorage.getItem('currentRole'))
    if (role) {
      this.$store.commit('setRole', role)

      Sentry.setContext('role', {
        id: role.id
      })

      this.$api.enableRole(role.id)

      try {
        const contract = await this.$api.getContract()
        this.$store.commit('setContract', contract)

        const request = await this.$api.getTeachingSystems()
        this.$store.commit('setTeachingSystems', request.data.data)
      } catch (e) {
        // FIXME: Lidar com erros
      }
    }

    await this.checkGuest()
  },

  errorCaptured (err) {
    utils.showLogs(err)

    // Retornar false quando não desejar enviar para o SENTRY.

    // HTTP 419 Authentication Timeout (não está no RFC 2616)
    // A autenticação expirou.
    if (err?.response?.status === 419) {
      return false
    }

    // HTTP 401 Unauthorized
    // Semânticamente é unauthenticated, o usuário não está autenticado.
    if (err?.response?.status === 401) {
      return false
    }

    // Exibimos uma mensagem para o usuário
    this.errorMessage = err.response?.data?.message || err.response?.message
    if (process.env.VUE_APP_PLATFORM === 'mobile' && !this.errorMessage) {
      this.errorMessage = 'Ocorreu um erro, tente novamente mais tarde.'
    }
    if (this.errorMessage) {
      this.errorModal = true
      return false
    }
  },

  methods: {
    setSize () {
      const screen =
        {
          small: 0,
          medium: 768,
          large: 992
        }
      const iw = window.innerWidth
      let size = null
      for (const s in screen) {
        if (iw >= screen[s]) size = s
      }
      let mobile = false
      if (size === 'small') {
        mobile = true
      } else {
        mobile = false
      }
      this.$store.commit('setSizeMobile', mobile)
    },

    onNetworkStatusChange (status) {
      this.offline = !status.connected
    },

    onErrorModalOk () {
      this.errorMessage = null
      this.errorModal = false
    },

    onErrorModalBack () {
      this.errorModal = false
      this.$router.back()
    },

    setupAxiosToken () {
      if (localStorage.getItem('token')) {
        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
      }
    },

    async checkGuest () {
      try {
        const response = await axios.get('/v1/auth/me')
        const user = response.data.data
        localStorage.setItem('user', JSON.stringify(user))

        Sentry.setUser({
          id: user.id,
          email: user.email,
          name: user.name
        })

        this.loggedIn = true
      } catch {
        this.loggedIn = false
      }

      this.isAuthResolved = true
    },
    async openAppStore () {
      await AppUpdate.openAppStore()
    },

    async setupMobile () {
      if (process.env.VUE_APP_PLATFORM === 'mobile') {
        try {
          const result = await AppUpdate.getAppUpdateInfo()
          if (parseInt(result.availableVersionCode) > parseInt(result.currentVersionCode)) {
            this.mandatoryUpdate = true
          }
        } catch (err) {
          // TODO-WILL: LIDAR COM ERRO AO VERIFICAR ATUALIZAÇÃO DO APP
        }
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
