<template>
  <div>
    <div
      v-if="loading"
      class="d-flex justify-content-center"
      style="min-height: calc(100vh - 70px);"
    >
      <b-spinner
        class="m-auto"
        label="Spinning"
      />
    </div>

    <Roles
      v-else
      :roles="roles"
      :user="user"
      :has-role="hasRole"
      @selectedRole="onRoleClick"
    />
    <TermsOfUse
      v-model="modalTerms"
      @close="checks"
      @recuseTerms="modalRecuseTerms = true"
      @error="handleServerError($event)"
    />

    <RecuseTerms
      v-model="modalRecuseTerms"
      @readTerms="modalTerms = true"
    />

    <ChangeTemporaryPassword
      v-model="modalUpdatePass"
      @error="handleServerError($event)"
      @close="checks"
    />

    <NoSchoolCourse
      v-model="modalNoSchoolCourse"
      @close="modalNoSchoolCourse = false"
    />

    <ContractUnavailable
      v-model="modalUnavailable"
      :msg-modal="msgModal"
      @close="modalUnavailable = false"
    />
  </div>
</template>

<script>
import TermsOfUse from '@/components/portal/enrollment/TermsOfUse.vue'
import RecuseTerms from '@/components/portal/enrollment/RecuseTerms.vue'
import NoSchoolCourse from '@/components/portal/enrollment/NoSchoolCourse.vue'
import ChangeTemporaryPassword from '@/components/portal/enrollment/ChangeTemporaryPassword.vue'
import ContractUnavailable from '@/components/portal/enrollment/ContractUnavailable.vue'
import Roles from '@/components/portal/enrollment/Roles.vue'

import utils from '../api/utils.js'
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import { types as roleTypes } from '@/enums/roleType'

export default {

  components: {
    TermsOfUse,
    RecuseTerms,
    ChangeTemporaryPassword,
    NoSchoolCourse,
    ContractUnavailable,
    Roles
  },

  mixins: [
    handlesServerValidation
  ],

  layout: 'Enrollment',

  data: () => ({
    modalTerms: false,
    modalRecuseTerms: false,
    modalUpdatePass: false,
    modalNoSchoolCourse: false,
    modalUnavailable: false,

    msgModal: '',

    user: {},
    loading: true,
    hasRole: true,
    roles: []
  }),

  async created () {
    utils.deleteEnrollment()
    this.$store.commit('setSchoolCourse', null)
    await this.checks()
  },

  methods: {

    async checks () {
      this.user = utils.getUser()
      this.modalTerms = false
      this.modalRecuseTerms = false
      this.modalUpdatePass = false

      if (this.user.should_accept_terms === true) {
        this.modalTerms = true
        return
      }
      if (this.user.should_change_password) {
        this.modalUpdatePass = true
        return
      }
      await this.fetchHomeContent()
    },

    async fetchHomeContent () {
      await this.getHierarchy()
      await this.getTeachingSystems()
      await this.getSubjects()
      await this.getRoles()
    },

    async getHierarchy () {
      try {
        const request = await this.$api.getHierarchy()
        utils.setHierarchy(request.data.data)
      } catch (e) {
        this.handleServerError(e)
      }
    },

    async getTeachingSystems () {
      try {
        const request = await this.$api.getTeachingSystems()
        this.$store.commit('setTeachingSystems', request.data.data)
      } catch (e) {
        this.handleServerError(e)
      }
    },

    async getSubjects () {
      try {
        const request = await this.$api.getSubjects()
        utils.setSubjects(request.data.data)
      } catch (e) {
        this.handleServerError(e)
      }
    },

    async getRoles () {
      this.loading = true
      try {
        this.roles = await this.$api.roles()
        if (this.roles.length < 1) {
          this.hasRole = false
        }
        if (this.roles.length === 1) {
          await this.onRoleClick(this.roles[0])
        }
      } catch (e) {
        this.handleServerError(e)
      }
      this.loading = false
    },

    async onRoleClick (role) {
      if (role.institution.is_defaulting) {
        await this.isDefaulting(role)
        return
      }
      if (role.type === roleTypes.Student) {
        const contractItemIds = role.school_courses.map((item) => item.contract_grade_level_item_id)
        if (contractItemIds.length === 0 || role.school_courses.length === 0 || contractItemIds.every(item => item === null)) {
          this.modalNoSchoolCourse = true
          return
        }
      }

      try {
        await this.$api.enableRole(role.id)
        await this.checkContract(role)
      } catch (e) {
        this.handleServerError(e)
      }
    },

    async checkContract (role) {
      try {
        const contract = await this.$api.getContract()
        this.$store.commit('setContract', contract)
        this.registerAcess(role)
      } catch (e) {
        if (e.response?.data?.error === 'institution_does_not_have_a_current_contract') {
          if (role.type === roleTypes.Teacher || role.type === roleTypes.Student) {
            this.msgModal = 'Identificamos um problema cadastral, entre em contato com sua Instituição para mais informações.'
          }
          if (role.type === roleTypes.Maintainer || role.type === roleTypes.Coordinator) {
            this.msgModal = 'Identificamos um problema com seu contrato, entre em contato com a Aude para regularizar. (21 97102-1781)'
          }
          this.modalUnavailable = true
        } else {
          this.handleServerError(e)
        }
      }
    },

    registerAcess (role) {
      this.$api.registerAcess()
      utils.setRole(role)
      utils.setSchoolCourses(role.school_courses)
      const route = utils.getHome()
      this.$router.push({ name: route })
    },

    async isDefaulting (role) {
      try {
        await this.$api.enableRole(role.id)
        const defaulting = await this.$api.defaulting()
        this.msgModal = defaulting.data.data.message
        this.modalUnavailable = true
      } catch (e) {
        this.handleServerError(e)
      }
    }
  }
}
</script>
