<template>
  <div class="vh-100 d-flex justify-content-center align-items-center">
    <div
      class="au-auth-card"
    >
      <LogoAude style="height: 70px; margin-bottom: 10px;" />
      <b-overlay
        :show="show"
        rounded="lg"
      >
        <template v-if="newPass">
          <UpdatePassword
            @newpass="registerPass($event)"
          />
        </template>
        <template v-if="!newPass">
          <div style="text-align: center;">
            <img
              class="mt-5 mb-3"
              src="../assets/confirmacao.svg"
              width="140px"
            >
            <br>
            Tudo certo ! <br>
            Vamos começar a estudar
            <AuButton
              class="mt-4 au-button-primary au-button-block"
              label="OK"
              @click="goHome()"
            />
          </div>
        </template>
      </b-overlay>
    </div>
  </div>
</template>
<script>
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import LogoAude from '@/assets/LogoAude.vue'
import UpdatePassword from '@/components/UpdatePassword.vue'

export default {
  components: {
    LogoAude,
    UpdatePassword
  },

  mixins: [
    handlesServerValidation
  ],

  layout: 'Auth',

  data () {
    return {
      newPass: true,
      url: '',
      show: false
    }
  },

  created () {
    this.fetchHomeContent()
  },

  methods: {

    async fetchHomeContent () {
      this.url = this.$route.query.update_url
      if (!this.url) {
        this.goHome()
      }
    },

    async registerPass (password) {
      this.show = true
      const data = {
        password: password
      }
      try {
        await this.$api.getCookie()
        await this.$api.newPass(this.url, data)
        this.newPass = false
      } catch (e) {
        this.handleServerError(e)
      }
      this.show = false
    },

    goHome () {
      this.$router.push({ name: 'Enrollment' })
    }
  }
}
</script>
